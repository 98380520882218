<div class="empty" *ngIf="scrolled" [ngStyle]="{ height: headerHeight + 'px' }">123</div>
<header
  itemscope
  itemtype="https://schema.org/WPHeader"
  (window:scroll)="checkScroll()"
  [class.scrolled]="scrolled"
  [class.mobile-state-show]="show"
  [class.mobile-state-hide]="!show"
  #header
>
  <div class="logo">
    <div class="container">
      <div class="header-logo">
        <a [routerLink]="['/']" class="logo-container">
          <img width="50" height="100" alt="Логотип" ngSrc="assets/img/logo.png" />
          <div class="header-items">
            <div class="site-name">На-час.бел</div>
            <div class="logo-description">{{ description }}</div>
            <div class="logo-workTime">{{ workTime }}</div>
          </div>
        </a>
        <div class="show-mobile">
          <button mat-mini-fab color="warn" (click)="show = !show" title="Меню" aria-label="меню">
            <mat-icon>{{ show ? 'close' : 'menu' }}</mat-icon>
          </button>
        </div>
      </div>

      <div class="contacts">
        <a href="tel:{{ phone }}">
          {{ phone }}<span class="mts">{{ operator }}</span>
        </a>
        <button mat-flat-button (click)="sendMessage()" color="warn">Вызвать мастера</button>
      </div>
    </div>
  </div>
  <div class="nav">
    <div class="container">
      <nav itemscope itemtype="https://schema.org/SiteNavigationElement">
        <a [routerLink]="['uslugi-electrika']" [routerLinkActive]="'active'">Услуги электрика</a>
        <a [routerLink]="['uslugi-santehnika']" [routerLinkActive]="'active'">Услуги сантехника</a>
        <a [routerLink]="['sborka-mebeli']" [routerLinkActive]="'active'">Сборка мебели</a>
        <a [routerLink]="['master-na-chas']" [routerLinkActive]="'active'">Муж на час</a>
        <a [routerLink]="['otzivi']" [routerLinkActive]="'active'">Отзывы</a>
        <a [routerLink]="['contacti']" [routerLinkActive]="'active'">Контакты</a>
      </nav>
    </div>
  </div>
</header>
