import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { RouteDataService } from '@services/route-data.service';
import { MetaService } from '@services/meta.service';
import { Title } from '@angular/platform-browser';
import { Shared } from '@shared/shared';
import { SendDataService } from '@services/send-data-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  providers: [
    Shared.provideNgxMask()
  ],
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  constructor(
    private routeDataService: RouteDataService,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((val) => val instanceof NavigationEnd))
      .subscribe(() => {
        const data = this.routeDataService.getRouteData(this.route);
        const description = data.slice(-1)?.[0]?.description ?? ''
        this.metaService.description(description);
        const title = this.title.getTitle()
        this.metaService.ogTitle(title);
        const robots = data.slice(-1)?.[0]?.robots ?? null
        this.metaService.robots(robots);
      })
  }
}
