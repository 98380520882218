import { Injectable, afterNextRender } from "@angular/core";
import { SchemaContact, SchemaRating, SchemaWebSite } from "../consts/schema.list";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { RouteDataService } from "./route-data.service";

@Injectable({
    providedIn: 'root'
})
export class GenSchemaService {
    constructor(private title: Title, private route: ActivatedRoute, private routeDataService: RouteDataService) {
    }
    rating() {
        const title = this.getTitle()
        return {
            ...SchemaRating,
            "url": typeof window !== 'undefined' ? window.location.href : '',
            "name": title,
            "description": title,
        }
    }
    contact() {
        const title = this.getTitle()
        return {
            ...SchemaContact,
            "url": typeof window !== 'undefined' ? window.location.href : '',
            "name": title,
            "description": title
        }
    }
    webSite() {
        return {
            ...SchemaWebSite
        }
    }
    private getTitle() {
        const data = this.routeDataService.getRouteData(this.route)
        const title = data[data.length - 1]?.title ?? null
        return title ?? this.title.getTitle()
    }
}