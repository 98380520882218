<div class="footer-form">
  <div class="banner-shadow"></div>
  <div class="footer-form-content container">
    <div>
      <h2>Мы наберем вам в течении минуты</h2>
      <div class="footer-form-fields">
        <mat-form-field class="field">
          <mat-label>Телефон</mat-label>
          <input type="text" [formControl]="control" matInput  [showMaskTyped]="true" [dropSpecialCharacters]="false" prefix="+375" mask="(00)000-00-00"
                 autocomplete="off">
          <mat-icon matSuffix color="warn">call</mat-icon>
        </mat-form-field>
        <button mat-flat-button color="warn" (click)="save()" [appLoading]="loading" class="field big-button">Заказать звонок</button>
      </div>
    </div>
    <div class="footer-form-contacts">
      <h2>Или наберите нам сами</h2>
      <div>
        <a href="tel:{{phone}}" class="phone">{{phone}}</a> {{operator}}</div>
    </div>
  </div>
</div>
<footer>
  <div class="container">
    <div class="cred-links">
      <a href="assets/docs/agreement.pdf" target="_blank">Пользовательское соглашение</a>
      <a href="assets/docs/politika-konfidencialnosti.pdf" target="_blank">Политика конфеденциальности</a>
    </div>
    <div class="cred">© 2020 - 2023. Все права защищены. ООО "Пайплайн Системы" УНП 692206240</div>
  </div>
  <div #schemaContainer></div>
</footer>
