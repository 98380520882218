import { Routes } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { config } from 'config/config';

const title = config.domain
export const CoreRouting: Routes = [
    {
        path: '',
        title: 'Служба бытового обслуживания в Минске',
        data: {
            description: 'Служба бытового обслуживания в Минске - от розетки до ремонта'
        },
        component: LayoutComponent,
        children: [
            {
                path: '',
                title: 'Служба бытового обслуживания',
                data: {
                    description: 'Служба бытового обслуживания в Минске - от розетки до ремонта '
                },
                loadComponent: () => import('@pages/home/home.component').then(m => m.HomeComponent),
            },
            {
                path: 'contacti',
                title: title + ' - контакты',
                data: {
                    description: 'Контактная информация'
                },
                loadComponent: () => import('@pages/contacti/contacti.component').then(m => m.ContactiComponent)
            },
            {
                path: 'uslugi-electrika',
                title: 'Услуги электрика в Минске, от 20 рублей, гарантия на работы',
                data: {
                    title: 'Услуги электрика в Минске',
                    description: 'Профессиональный услуги электрика в Минске . От 20 рублей. Выезд в день обращения. Гарантия на все работы. Опытные электрики устранят все неисправности.'
                },
                loadComponent: () => import('@pages/uslugi-electrika/uslugi-electrika.component').then(m => m.UslugiElectrikaComponent)
            },
            {
                path: 'uslugi-santehnika',
                title: 'Услуги сантехника в Минске, от 20 рублей, гарантия на работы',
                data: {
                    title: 'Услуги сантехника в Минске',
                    description: 'Профессиональный услуги сантехника в Минске. От 20 рублей. Выезд в день обращения. Гарантия на все работы. Опытные сантехники устранят все неисправности.'
                },
                loadComponent: () => import('@pages/uslugi-santehnika/uslugi-santehnika.component').then(m => m.UslugiSantehnikaComponent),

            },
            {
                path: 'master-na-chas',
                title: 'Муж на час в Минске, от 20 рублей, гарантия на работы',
                data: {
                    title: 'Муж на час в Минске',
                    description: 'Муж на час в Минске. От 20 рублей. Выезд в день обращения. Гарантия на все работы. Опытные мастера устранят все неисправности, повесят полки, выкинут за собой мусор.'
                },
                loadComponent: () => import('@pages/master-na-chas/master-na-chas.component').then(m => m.MasterNaChasComponent),

            },
            {
                path: 'sborka-mebeli',
                title: 'Сборка мебели в Минске, от 20 рублей, гарантия на работы',
                data: {
                    title: 'Сборка мебели в Минске',
                    description: 'Сборка мебели в Минске. От 20 рублей. Выезд в день обращения. Гарантия на все работы. Профессиональные сборщики мебели в Минске - быстро и аккуратно соберут любую вашу мебель.'
                },
                loadComponent: () => import('@pages/sborka-mebeli/sborka-mebeli.component').then(m => m.SborkaMebeliComponent),

            },
            {
                path: 'vakansii',
                title: 'Вакансии',
                data: {
                    description: 'Наши вакансии'
                },
                loadComponent: () => import('@pages/vakansii/vakansii.component').then(m => m.VakansiiComponent),
            },
            {
                path: 'otzivi',
                title: 'Отзывы',
                data: {
                    description: 'Отзывы о наше работе '
                },
                loadComponent: () => import('@pages/otzivi/otzivi.component').then(m => m.OtziviComponent),

            },
            {
                path: 'not-found',
                title: '404 - NOT FOUND',
                data: {
                    robots: 'follow, noarchive, noindex',
                    description: '404 NOT FOUND'
                },
                loadComponent: () => import('@pages/not-found/not-found.component').then(m => m.MainComponent),

            },
            {
                path: '**',
                redirectTo: 'not-found',
                pathMatch: 'full'
            },
        ]
    },

];