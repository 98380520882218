import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouteDataModel } from "../models/route-data.model";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class RouteDataService {
    getRouteData(activatedRoute: ActivatedRoute): RouteDataModel[] {
        let routeData: RouteDataModel[] = [];
        while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
            const item = this.dataValue(activatedRoute);
            if (item) {
                routeData = [
                    ...routeData,
                    {
                        ...item,
                    },
                ];
            }
        }
        const routeDataValue = this.dataValue(activatedRoute);
        if (routeDataValue) {
            routeData = [...routeData, { ...routeDataValue }];
        }
        routeData = this.removeDuplicate(routeData)
        return routeData;
    }
    private dataValue(data: ActivatedRoute): RouteDataModel | null {
        const dataValue: RouteDataModel = (data.data as BehaviorSubject<RouteDataModel>).value;
        if (Object.keys(dataValue).length > 0) {
            return { ...dataValue };
        }
        return null;
    }
    private removeDuplicate(routeData: RouteDataModel[]): RouteDataModel[] {
        return routeData.reduce<RouteDataModel[]>((accum, item) => {
            if (!accum.find((find: RouteDataModel) => find.description === item.description)) {
                return [...accum, item];
            }
            return accum;
        }, []);
    }
}