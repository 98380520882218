import { Component } from '@angular/core';
import { config } from 'config/config';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SharedMaterial } from '@shared/material/shared-material';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    FooterComponent, RouterOutlet, HeaderComponent, SharedMaterial.MatIconModule, SharedMaterial.MatButtonModule
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  title = config.siteName;
  phone = config.phone

  constructor() { }
}
