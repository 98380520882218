import { Component, ElementRef, OnInit, ViewChild, afterNextRender } from '@angular/core';
import { SendDataService } from '@services/send-data-service';
import { config } from 'config/config';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { Shared } from '@shared/shared';
import { SharedMaterial } from '@shared/material/shared-material';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    Shared.CommonModule,
    Shared.RouterModule,
    Shared.NgOptimizedImage,
    SharedMaterial.MatButtonModule,
    SharedMaterial.MatIconModule,
    SharedMaterial.MatDialogModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('empty') emptyRef!: ElementRef;
  @ViewChild('header') headerRef!: ElementRef;
  scrolled = false;
  phone = config.phone
  operator = config.operator
  description = config.siteName
  workTime = config.workTime
  show = false;
  headerHeight = 0;
  sendMessage() {
    this.sendDataService.sendMessage()
  }
  checkScroll() {
    if (window.scrollY >= 1) {
      this.scrolled = true
    } else {
      this.scrolled = false
    }
    this.headerHeight = this.headerRef?.nativeElement?.clientHeight
  }

  constructor(private sendDataService: SendDataService, private router: Router) {
    afterNextRender(() => {
      this.checkScroll();
    })
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((events) => events instanceof NavigationEnd))
      .subscribe(() => {
        this.show = false
      })
  }
}
