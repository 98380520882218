<div class="doc-container">
  <app-header></app-header>
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
  <div class="call-button">
    <a href="tel:{{ phone }}" aria-label="Позвонить" title="Позвонить">
      <button mat-fab color="warn" aria-label="Позвонить" title="Позвонить">
        <mat-icon>phone_enabled</mat-icon>
      </button>
    </a>
  </div>
</div>
