import { config } from "config/config"
export const SchemaWebSite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": config.siteName,
    "url": typeof window !== 'undefined' ? window.location.host : ''
}
export const SchemaRating = {
    '@context': "http://schema.org",
    "@type": "Product",
    "name": config.siteName,
    "brand": "НА-ЧАС.БЕЛ",
    "offers": {
        "@type": "AggregateOffer",
        "highPrice": config.price.end,
        "lowPrice": config.price.start,
        "offerCount": config.offerCount,
        "priceCurrency": config.currency
    },
    "AggregateRating": {
        "ratingValue": "4.65",
        "reviewCount": "72",
        "bestRating": "5",
        "itemReviewed": config.siteName
    },
    "url": typeof window !== 'undefined' ? window.location.href : '',
    "description": config.siteName,
    "image": config.logoUrl
}
export const SchemaContact = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": config.siteName,
    "url": typeof window !== 'undefined' ? window.location.href : '',
    "currenciesAccepted": "BRL",
    "paymentAccepted": "Cash, Credit Card",
    "openingHours": "Mo-Su",
    "telephone": config.phone,
    "logo": typeof window !== 'undefined' ? window.location.hostname : '' + '/' + config.logoUrl,
    "image": typeof window !== 'undefined' ? window.location.hostname : '' + '/' + config.logoUrl,
    "priceRange": "от " + config.price.start + " до " + config.price.end + " BYN",
    "address": {
        "@type": "PostalAddress",
        "addressCountry": config.addressCountry,
        "addressRegion": config.addressRegion,
        "addressLocality": config.addressLocality,
        "streetAddress": config.streetAddress,
        "postalCode": config.postalCode
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "reviewCount": config.reviewCount,
        "ratingValue": config.ratingValue,
        "bestRating": "5",
        "worstRating": "1"
    },
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "telephone": config.phone,
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": config.areaCodeISO
        },
        {
            "@type": "ContactPoint",
            "telephone": config.phone,
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": config.areaCodeISO
        }
    ]
}