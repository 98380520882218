import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs';
import { GenSchemaService } from '@services/gen-shema.service';
import { SendDataService } from '@services/send-data-service';
import { config } from 'config/config';
import { SharedMaterial } from '@shared/material/shared-material';
import { Shared } from '@shared/shared';
import { LoadingDirective } from '@shared/directive/items/loading.directive';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    Shared.CommonModule,
    SharedMaterial.MatIconModule,
    SharedMaterial.MatFormFieldModule,
    SharedMaterial.MatInputModule,
    SharedMaterial.MatButtonModule,
    Shared.ReactiveFormsModule,
    Shared.NgxMaskDirective,
    LoadingDirective
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  @ViewChild('schemaContainer') schemaContainer: ElementRef | null = null
  phone = config.phone
  operator = config.operator
  control = new FormControl('', [Validators.required, Validators.minLength(17), Validators.maxLength(17)])
  loading = false;
  schemaRating = {};
  schemaCompany = {};
  schemaWebSite = {};
  save() {
    const value = this.control.value
    if (!value) {
      this.sendDataService.showMessage('Введите номер', 'Ошибка')
      return
    }
    const valueLength = this.sendDataService.validatePhone(value);
    if (!valueLength) {
      this.sendDataService.showMessage('Введите полный номер', 'Ошибка')
      return;
    }
    this.sendDataService.sendDataMessage(value, 'callBack').then((data) => {
      if (!data.errors) {
        this.sendDataService.showMessage('Сообщение успешно отправлено', '')
      } else {
        this.sendDataService.showMessage(data.errors?.message?.join(', '), 'Ошибка')
      }
      this.loading = false
      this.control.reset()
    }).catch(() => {
      this.loading = false
      this.sendDataService.showMessage('Ошибка отправки сообщения', 'Ошибка')
    })
  }
  insertScript(data: string[]) {
    if (!this.schemaContainer) {
      return;
    }
    const childElements: HTMLCollection[] | null = Array.from(this.schemaContainer?.nativeElement?.children) ?? null;

    for (let child of childElements) {
      this.renderer.removeChild(this.schemaContainer?.nativeElement, child);
    }
    data.forEach((item) => {
      let script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = item
      this.renderer.appendChild(this.schemaContainer?.nativeElement, script);
    })
  }
  private getSchema() {
    const schemas = [
      JSON.stringify(this.genSchemaService.rating()),
      JSON.stringify(this.genSchemaService.contact()),
      JSON.stringify(this.genSchemaService.webSite()),
    ]
    this.insertScript(schemas)
  }
  constructor(private renderer: Renderer2,
    private sendDataService: SendDataService,
    private router: Router, private genSchemaService: GenSchemaService) {

  }
  ngAfterViewInit(): void {
    this.getSchema()
    this.router.events
      .pipe(filter((val) => val instanceof NavigationEnd), debounceTime(100))
      .subscribe(() => {
        this.getSchema()
      })
  }

}
