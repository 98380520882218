import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    constructor(private meta: Meta) { }
    description(value: string) {
        this.meta.updateTag({ name: 'description', content: value });
    }
    ogTitle(value: string) {
        this.meta.updateTag({ property: 'og:title', content: value });
    }
    robots(value: string | null) {
        this.meta.removeTag('robots');
        if (value) {
            this.meta.updateTag({ name: 'robots', content: value });
        } else {
            this.meta.updateTag({ name: 'robots', content: 'all' });
        }
    }
}