import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from "@angular/router";
import { provideClientHydration } from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { CoreRouting } from "@core/routes/core-routing";
const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);
export const AppConfig = {
    providers: [
        provideRouter(CoreRouting, inMemoryScrollingFeature),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideClientHydration()
    ]
}